import React, { useState } from 'react';
import {
  TextField,
  Button,
  Typography,
  Box,
  Container,
  Alert,
  CircularProgress,
  Link,
} from '@mui/material';
import axios from 'axios';

const PasswordResetRequestForm = ({ onBackToLogin }) => {
  const [email, setEmail] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [submitStatus, setSubmitStatus] = useState({ type: '', message: '' });

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!email) return;

    setIsLoading(true);
    setSubmitStatus({ type: '', message: '' });

    try {
      await axios.post(
        `${process.env.REACT_APP_API_URL}/api/password-reset-request`,
        { email }
      );
      setSubmitStatus({
        type: 'success',
        message: 'If an account exists with this email, you will receive password reset instructions within 5 minutes.',
      });
      setEmail('');
    } catch (error) {
      setSubmitStatus({
        type: 'error',
        message: 'An error occurred. Please try again later.',
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Container maxWidth="sm">
      <Box
        sx={{
          marginTop: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Typography component="h1" variant="h4" sx={{ mb: 4 }}>
          Reset Password
        </Typography>

        {submitStatus.message && (
          <Alert
            severity={submitStatus.type}
            sx={{ width: '100%', mb: 2 }}
          >
            {submitStatus.message}
          </Alert>
        )}

        <Box component="form" onSubmit={handleSubmit} sx={{ width: '100%' }}>
          <TextField
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email Address"
            name="email"
            autoComplete="email"
            autoFocus
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            sx={{ mb: 2 }}
          />

          <Button
            type="submit"
            fullWidth
            variant="contained"
            disabled={isLoading}
            sx={{
              py: 1.5,
              bgcolor: 'black',
              '&:hover': {
                bgcolor: '#333',
              },
            }}
          >
            {isLoading ? <CircularProgress size={24} /> : 'Send Reset Link'}
          </Button>

          <Box sx={{ mt: 2, textAlign: 'center' }}>
            <Link
              href="#"
              variant="body2"
              sx={{ color: 'text.secondary' }}
              onClick={(e) => {
                e.preventDefault();
                onBackToLogin();
              }}
            >
              Back to Login
            </Link>
          </Box>
        </Box>
      </Box>
    </Container>
  );
};

export default PasswordResetRequestForm;
