import axios from 'axios';

export const reportError = async (error, componentStack = null) => {
  try {
    await axios.post(`${process.env.REACT_APP_API_URL}/api/client-error`, {
      error: {
        message: error.message,
        name: error.name,
        stack: error.stack,
        code: error.code,
      },
      componentStack,
      url: window.location.href,
      timestamp: new Date().toISOString(),
      userAgent: navigator.userAgent,
      platform: navigator.platform,
      language: navigator.language,
      screenSize: {
        width: window.screen.width,
        height: window.screen.height,
        pixelRatio: window.devicePixelRatio,
      },
      viewport: {
        width: window.innerWidth,
        height: window.innerHeight,
      },
      connection: navigator.connection ? {
        type: navigator.connection.effectiveType,
        downlink: navigator.connection.downlink,
        rtt: navigator.connection.rtt,
      } : null,
    });
  } catch (reportingError) {
    // Don't throw here - we don't want errors in error reporting
    console.error('Failed to report error:', reportingError);
  }
}; 