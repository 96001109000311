import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  Container,
  Typography,
  CircularProgress,
  TextField,
  Switch,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  useTheme,
  useMediaQuery,
  Card,
  CardContent,
} from '@mui/material';
import EmailIcon from '@mui/icons-material/Email';
import FavoriteIcon from '@mui/icons-material/Favorite';
import axios from 'axios';
import Footer from './Footer';

const Pulse = ({ onLogout, userEmail }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [pulseTopics, setPulseTopics] = useState([]);
  const [trials, setTrials] = useState([]);
  const [newTopic, setNewTopic] = useState('');
  const [error, setError] = useState('');
  const [curatedPulseTopics, setCuratedPulseTopics] = useState([]);
  const [newCuratedTopic, setNewCuratedTopic] = useState('');
  const [availableCuratedTopics, setAvailableCuratedTopics] = useState({});
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  // Move isAdmin declaration before useEffect
  const isAdmin = ['test@test.com', 'mail@andrewjm.co'].includes(userEmail);

  // Fetch pulse topics and trials on component mount
  useEffect(() => {
    fetchPulseTopics();
    fetchTrials();
    fetchAvailableCuratedTopics();
  }, []);

  useEffect(() => {
    if (isAdmin) {
      fetchCuratedPulseTopics();
    }
  }, [isAdmin]);

  const fetchPulseTopics = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/pulse-topics`,
        { withCredentials: true }
      );
      setPulseTopics(response.data);
    } catch (error) {
      console.error('Error fetching pulse topics:', error);
      setError('Failed to fetch pulse topics');
    }
  };

  const fetchTrials = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/trials`,
        { withCredentials: true }
      );
      setTrials(response.data);
    } catch (error) {
      console.error('Error fetching trials:', error);
      setError('Failed to fetch trials');
    }
  };

  const fetchCuratedPulseTopics = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/curated-pulse-topics`,
        { withCredentials: true }
      );
      setCuratedPulseTopics(response.data);
    } catch (error) {
      console.error('Error fetching curated pulse topics:', error);
      setError('Failed to fetch curated pulse topics');
    }
  };

  const fetchAvailableCuratedTopics = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/curated-pulse-topics/available`,
        { withCredentials: true }
      );
      setAvailableCuratedTopics(response.data);
    } catch (error) {
      console.error('Error fetching available curated topics:', error);
      setError('Failed to fetch available curated topics');
    }
  };

  const handleCreateTopic = async (e) => {
    e.preventDefault();
    if (!newTopic.trim()) return;

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/pulse-topics`,
        { topic: newTopic },
        { withCredentials: true }
      );
      setPulseTopics([response.data, ...pulseTopics]);
      setNewTopic('');
    } catch (error) {
      console.error('Error creating pulse topic:', error);
      setError('Failed to create pulse topic');
    }
  };

  const handleToggleTopic = async (id, currentEnabled) => {
    try {
      const response = await axios.patch(
        `${process.env.REACT_APP_API_URL}/api/pulse-topics/${id}/toggle`,
        {},
        { withCredentials: true }
      );
      setPulseTopics(pulseTopics.map(topic => 
        topic.id === id ? response.data : topic
      ));
    } catch (error) {
      console.error('Error toggling pulse topic:', error);
      setError('Failed to toggle pulse topic');
    }
  };

  const handleToggleTrial = async (id, currentEnabled) => {
    try {
      const response = await axios.patch(
        `${process.env.REACT_APP_API_URL}/api/trials/${id}/toggle`,
        {},
        { withCredentials: true }
      );
      setTrials(trials.map(trial => 
        trial.id === id ? response.data : trial
      ));
    } catch (error) {
      console.error('Error toggling trial:', error);
      setError('Failed to toggle trial');
    }
  };

  const handleCreateCuratedTopic = async (e) => {
    e.preventDefault();
    if (!newCuratedTopic.trim()) return;

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/curated-pulse-topics`,
        { topic: newCuratedTopic },
        { withCredentials: true }
      );
      setCuratedPulseTopics([response.data, ...curatedPulseTopics]);
      setNewCuratedTopic('');
    } catch (error) {
      console.error('Error creating curated pulse topic:', error);
      setError('Failed to create curated pulse topic');
    }
  };

  const handleToggleCuratedTopic = async (id, currentEnabled) => {
    try {
      const response = await axios.patch(
        `${process.env.REACT_APP_API_URL}/api/curated-pulse-topics/${id}/toggle`,
        {},
        { withCredentials: true }
      );
      setCuratedPulseTopics(curatedPulseTopics.map(topic => 
        topic.id === id ? response.data : topic
      ));
    } catch (error) {
      console.error('Error toggling curated pulse topic:', error);
      setError('Failed to toggle curated pulse topic');
    }
  };

  const handleToggleSubscription = async (id, currentSubscribed) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/curated-pulse-topics/${id}/subscribe`,
        {},
        { withCredentials: true }
      );

      // Update the topic's subscription status in the nested structure
      setAvailableCuratedTopics(prevTopics => {
        const newTopics = { ...prevTopics };
        
        // Iterate through categories
        Object.keys(newTopics).forEach(category => {
          // Iterate through subcategories
          Object.keys(newTopics[category]).forEach(subCategory => {
            // Update the specific topic if found
            newTopics[category][subCategory] = newTopics[category][subCategory].map(topic =>
              topic.id === id ? { ...topic, isSubscribed: response.data.isSubscribed } : topic
            );
          });
        });
        
        return newTopics;
      });
    } catch (error) {
      console.error('Error toggling subscription:', error);
      setError('Failed to toggle subscription');
    }
  };

  const handleLogout = async () => {
    setIsLoading(true);
    try {
      await axios.post(
        `${process.env.REACT_APP_API_URL}/api/logout`,
        {},
        { withCredentials: true }
      );
      onLogout();
    } catch (error) {
      console.error('Error logging out:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleUpdateCategories = async (id, category, sub_category) => {
    try {
      const response = await axios.patch(
        `${process.env.REACT_APP_API_URL}/api/curated-pulse-topics/${id}/categories`,
        { category, sub_category },
        { withCredentials: true }
      );
      setCuratedPulseTopics(curatedPulseTopics.map(topic => 
        topic.id === id ? response.data : topic
      ));
    } catch (error) {
      console.error('Error updating categories:', error);
      setError('Failed to update categories');
    }
  };

  return (
    <Box
      sx={{
        minHeight: '100vh',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <Container 
        maxWidth="md" 
        sx={{
          px: isMobile ? 2 : 3,
          py: isMobile ? 2 : 4,
          flex: 1,
        }}
      >
        <Box
          component="header"
          sx={{
            position: 'sticky',
            top: 0,
            bgcolor: 'background.paper',
            zIndex: 1,
            pb: 2,
            borderBottom: '1px solid',
            borderColor: 'divider',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end', // Changed to pull logout button to the right
            gap: 2,
          }}
        >
          <Typography 
            component="h1" 
            variant={isMobile ? "h5" : "h4"} 
            sx={{ 
              fontWeight: 600,
              minWidth: 'fit-content',
              mr: 2,
            }}
          >
            dist*ll
          </Typography>

          <Button
            onClick={handleLogout}
            variant="text"
            disabled={isLoading}
            sx={{
              py: 1,
              px: 2,
              color: 'black', // Changed to black text
              border: '1px solid transparent',
              '&:hover': {
                border: '1px solid black', // Changed to black border on hover
                bgcolor: 'transparent',
              },
              minWidth: 'auto',
              ml: 1,
            }}
          >
            {isLoading ? <CircularProgress size={24} /> : 'Logout'}
          </Button>
        </Box>

        <Card 
          sx={{ 
            mb: 2,
            mt: 3,
            backgroundColor: '#f8f8f8',
            border: '1px solid #eaeaea',
            boxShadow: 'none',
          }}
        >
          <CardContent sx={{ 
            display: 'flex', 
            alignItems: 'center',
            gap: 1,
            py: 1,
            '&:last-child': { pb: 1 }, // Override MUI's default padding
          }}>
            <FavoriteIcon sx={{ color: '#666' }} />
            <Typography variant="body1" color="text.secondary" textAlign="left">
              <a href="https://buy.stripe.com/eVa16Iejl9DjdSU000" target="_blank" rel="noopener noreferrer">Support the developer</a> to help cover the costs of running this service. (approx. $400-500/month)
            </Typography>
          </CardContent>
        </Card>

        <Card 
          sx={{ 
            mb: 3,
            mt: 0,
            backgroundColor: '#f8f8f8',
            border: '1px solid #eaeaea',
            boxShadow: 'none',
          }}
        >
          <CardContent sx={{ 
            display: 'flex', 
            alignItems: 'center',
            gap: 1,
            py: 1,
            '&:last-child': { pb: 1 }, // Override MUI's default padding
          }}>
            <EmailIcon sx={{ color: '#666' }} />
            <Typography variant="body1" color="text.secondary" textAlign="left">
              Sending daily pulses to <strong>{userEmail}</strong> between 7am and 8am EST
            </Typography>
          </CardContent>
        </Card>

        {trials && trials.length > 0 && (
          <Box sx={{ mb: 4 }}>
            <Typography variant="h3" sx={{ mb: 0, textAlign: 'left' }}>
              Trial
            </Typography>
            <Typography variant="body1" sx={{ mb: 2, textAlign: 'left' }}>
              Toggle off to disable.
            </Typography>
            <List>
              {trials.map((trial) => (
                <ListItem
                  key={trial.id}
                  sx={{
                    border: '1px solid #eaeaea',
                    borderRadius: 1,
                    mb: 1,
                    bgcolor: '#f8f8f8',
                  }}
                >
                  <ListItemText 
                    primary={trial.topic}
                  />
                  <ListItemSecondaryAction>
                    <Switch
                      edge="end"
                      checked={trial.enabled}
                      onChange={() => handleToggleTrial(trial.id, trial.enabled)}
                    />
                  </ListItemSecondaryAction>
                </ListItem>
              ))}
            </List>
          </Box>
        )}

        <Typography variant="h3" sx={{ mb: 0, textAlign: 'left' }}>
          Pulses
        </Typography>
        <Typography variant="body1" sx={{ mb: 2, textAlign: 'left' }}>
          Subscribe to our most popular pulses.
        </Typography>
        {Object.entries(availableCuratedTopics).map(([category, subCategories]) => {
          // Filter out empty subcategories first
          const nonEmptySubCategories = Object.entries(subCategories).filter(
            ([_, topics]) => topics && topics.length > 0
          );

          // Only render category if it has any non-empty subcategories
          if (nonEmptySubCategories.length === 0) return null;

          return (
            <Box key={category} sx={{ mb: 4 }}>
              <Typography variant="h5" sx={{ mb: 0, textAlign: 'left' }}>
                {category}
              </Typography>
              {nonEmptySubCategories.map(([subCategory, topics]) => (
                <Box key={subCategory} sx={{ mb: 0 }}>
                  <Typography variant="body1" sx={{ mb: 0, fontStyle: 'italic', textAlign: 'left' }}>
                    {subCategory}
                  </Typography>
                  <List>
                    {topics.map((topic) => (
                      <ListItem
                        key={topic.id}
                        sx={{
                          border: '1px solid #eaeaea',
                          bgcolor: '#f8f8f8',
                          borderRadius: 1,
                          mb: 1,
                        }}
                      >
                        <ListItemText primary={topic.topic} />
                        <ListItemSecondaryAction>
                          <Switch
                            edge="end"
                            checked={topic.isSubscribed}
                            onChange={() => handleToggleSubscription(topic.id, topic.isSubscribed)}
                          />
                        </ListItemSecondaryAction>
                      </ListItem>
                    ))}
                  </List>
                </Box>
              ))}
            </Box>
          );
        })}

        <Box sx={{mb: 4 }}>
          <Typography variant="h3" sx={{ mb: 0, textAlign: 'left' }}>
            Custom pulses
          </Typography>
          <Typography variant="body1" sx={{ mb: 2, textAlign: 'left' }}>
            Keep it specific enough to be interesting, but broad enough to have new content every day.
          </Typography>
          <Box component="form" onSubmit={handleCreateTopic} sx={{ display: 'flex', gap: 2 }}>
            <TextField
              fullWidth
              value={newTopic}
              onChange={(e) => setNewTopic(e.target.value)}
              placeholder="Enter a topic to track..."
              size="small"
            />
            <Button
              type="submit"
              variant="contained"
              sx={{
                bgcolor: 'black',
                '&:hover': { bgcolor: '#333' }
              }}
            >
              Add
            </Button>
          </Box>
        </Box>

        <List>
          {pulseTopics.map((topic) => (
            <ListItem
              key={topic.id}
              sx={{
                border: '1px solid #eaeaea',
                bgcolor: '#f8f8f8',
                borderRadius: 1,
                mb: 1,
              }}
            >
              <ListItemText primary={topic.topic} />
              <ListItemSecondaryAction>
                <Switch
                  edge="end"
                  checked={topic.enabled}
                  onChange={() => handleToggleTopic(topic.id, topic.enabled)}
                />
              </ListItemSecondaryAction>
            </ListItem>
          ))}
        </List>

        {isAdmin && (
          <Box sx={{ mt: 4, mb: 4 }}>
              <Typography variant="h3" sx={{ mb: 2, textAlign: 'left' }}>
                Curated Pulse Topics
            </Typography>
            <Typography variant="body1" sx={{ mb: 2, textAlign: 'left' }}>
              Admin only.
            </Typography>
            <Box component="form" onSubmit={handleCreateCuratedTopic} sx={{ display: 'flex', gap: 2 }}>
              <TextField
                fullWidth
                value={newCuratedTopic}
                onChange={(e) => setNewCuratedTopic(e.target.value)}
                placeholder="Enter a curated topic..."
                size="small"
              />
              <Button
                type="submit"
                variant="contained"
                sx={{
                  bgcolor: 'black',
                  '&:hover': { bgcolor: '#333' }
                }}
              >
                Add
              </Button>
            </Box>

            <List sx={{ mt: 2 }}>
              {curatedPulseTopics.map((topic) => (
                <ListItem
                  key={topic.id}
                  sx={{
                    border: '1px solid #eaeaea',
                    borderRadius: 1,
                    mb: 1,
                    bgcolor: '#f8f8f8',
                    flexDirection: 'column',
                    alignItems: 'stretch',
                    py: 2
                  }}
                >
                  <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                    <ListItemText primary={topic.topic} />
                    <Switch
                      edge="end"
                      checked={topic.enabled}
                      onChange={() => handleToggleCuratedTopic(topic.id, topic.enabled)}
                    />
                  </Box>
                  <Box sx={{ display: 'flex', gap: 2 }}>
                    <TextField
                      size="small"
                      label="Category"
                      value={topic.category || ''}
                      onChange={(e) => handleUpdateCategories(topic.id, e.target.value, topic.sub_category)}
                      sx={{ flex: 1 }}
                    />
                    <TextField
                      size="small"
                      label="Sub-category"
                      value={topic.sub_category || ''}
                      onChange={(e) => handleUpdateCategories(topic.id, topic.category, e.target.value)}
                      sx={{ flex: 1 }}
                    />
                  </Box>
                </ListItem>
              ))}
            </List>
          </Box>
        )}
      </Container>
      <Footer />
    </Box>
  );
};

export default Pulse; 