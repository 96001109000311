import React, { useState } from 'react';
import {
  TextField,
  Button,
  Typography,
  Box,
  Container,
  Alert,
  CircularProgress,
  Link,
} from '@mui/material';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

const LoginForm = ({ onToggleForm, onLoginSuccess, onForgotPassword }) => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    email: '',
    password: '',
  });
  const [errors, setErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [submitStatus, setSubmitStatus] = useState({ type: '', message: '' });

  const validateForm = () => {
    const newErrors = {};
    if (!formData.email) {
      newErrors.email = 'Email is required';
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      newErrors.email = 'Email is invalid';
    }
    if (!formData.password) {
      newErrors.password = 'Password is required';
    }
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
    // Clear error when user starts typing
    if (errors[name]) {
      setErrors((prev) => ({
        ...prev,
        [name]: '',
      }));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateForm()) return;

    setIsLoading(true);
    setSubmitStatus({ type: '', message: '' });

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/login`, 
        formData,
        { withCredentials: true }
      );

      // Store token in localStorage as backup
      if (response.data.token) {
        localStorage.setItem('token', response.data.token);
        // Set default Authorization header for future requests
        axios.defaults.headers.common['Authorization'] = `Bearer ${response.data.token}`;
      }

      setSubmitStatus({
        type: 'success',
        message: 'Login successful!',
      });
      
      onLoginSuccess(response.data.user.email);
    } catch (error) {
      setSubmitStatus({
        type: 'error',
        message: error.response?.data?.error || 'An error occurred during login. Please use HTTPS while we resolve this issue.',
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Container maxWidth="sm">
      <Box
        sx={{
          marginTop: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Typography component="h1" variant="h4" sx={{ mb: 4 }}>
          Log In
        </Typography>

        {submitStatus.message && (
          <Alert
            severity={submitStatus.type}
            sx={{ width: '100%', mb: 2 }}
          >
            {submitStatus.message}
          </Alert>
        )}

        <Box component="form" onSubmit={handleSubmit} sx={{ width: '100%' }}>
          <TextField
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email Address"
            name="email"
            autoComplete="email"
            autoFocus
            value={formData.email}
            onChange={handleChange}
            error={!!errors.email}
            helperText={errors.email}
            sx={{ mb: 2 }}
          />

          <TextField
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            autoComplete="current-password"
            value={formData.password}
            onChange={handleChange}
            error={!!errors.password}
            helperText={errors.password}
            sx={{ mb: 3 }}
          />

          <Button
            type="submit"
            fullWidth
            variant="contained"
            disabled={isLoading}
            sx={{
              py: 1.5,
              bgcolor: 'black',
              '&:hover': {
                bgcolor: '#333',
              },
            }}
          >
            {isLoading ? <CircularProgress size={24} /> : 'Log In'}
          </Button>

          <Box sx={{ mt: 2, textAlign: 'center' }}>
            <Link
              href="#"
              variant="body2"
              sx={{ color: 'text.secondary', mr: 2 }}
              onClick={(e) => {
                e.preventDefault();
                navigate('/reset-request');
              }}
            >
              Forgot Password?
            </Link>
            <Link
              href="#"
              variant="body2"
              sx={{ color: 'text.secondary' }}
              onClick={(e) => {
                e.preventDefault();
                navigate('/signup');
              }}
            >
              Don't have an account? Sign Up
            </Link>
          </Box>
        </Box>
      </Box>
    </Container>
  );
};

export default LoginForm;
