import React from 'react';
import {
  Box,
  Container,
  Link,
  Typography,
  Stack,
  useTheme,
  useMediaQuery,
} from '@mui/material';
import RocketLaunchIcon from '@mui/icons-material/RocketLaunch';
import FeedbackIcon from '@mui/icons-material/Feedback';
import MapIcon from '@mui/icons-material/Map';

const Footer = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Box
      component="footer"
      sx={{
        mt: 'auto',
        py: 3,
        borderTop: '1px solid',
        borderColor: 'divider',
      }}
    >
      <Container 
        maxWidth="md"
        sx={{
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <Stack
          direction={isMobile ? 'column' : 'row'}
          spacing={isMobile ? 2 : 4}
          alignItems="center"
        >
          <Link
            href="https://www.tiktok.com/@andrew_ships"
            target="_blank"
            rel="noopener noreferrer"
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: 0.5,
              color: '#000',
              textDecoration: 'none',
              '&:hover': {
                color: '#9234eb',
              },
            }}
          >
            <RocketLaunchIcon sx={{ fontSize: 18 }} />
            An <strong>andrewships</strong> project
          </Link>

          <Typography
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: 0.5,
              color: '#000',
            }}
          >
            <FeedbackIcon sx={{ fontSize: 18 }} />
            Send feedback to <strong>andrew@distll.ai</strong>
          </Typography>
        </Stack>
      </Container>
    </Box>
  );
};

export default Footer; 