import React, { useState } from 'react';
import {
  TextField,
  Button,
  Typography,
  Box,
  Container,
  Alert,
  CircularProgress,
  Link,
} from '@mui/material';
import axios from 'axios';
import { validateEmail, validatePassword } from '../utils/validation';
import { useNavigate } from 'react-router-dom';
import { reportError } from '../utils/errorReporting';

const SignupForm = ({ onToggleForm }) => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    email: '',
    password: '',
  });
  const [touched, setTouched] = useState({
    email: false,
    password: false,
  });
  const [errors, setErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [submitStatus, setSubmitStatus] = useState({ type: '', message: '' });

  const validateField = (name, value) => {
    switch (name) {
      case 'email':
        return validateEmail(value);
      case 'password':
        return validatePassword(value, true);
      default:
        return '';
    }
  };

  const handleBlur = (e) => {
    const { name } = e.target;
    setTouched(prev => ({
      ...prev,
      [name]: true,
    }));
    const error = validateField(name, formData[name]);
    setErrors(prev => ({
      ...prev,
      [name]: error,
    }));
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value,
    }));
    if (touched[name]) {
      const error = validateField(name, value);
      setErrors(prev => ({
        ...prev,
        [name]: error,
      }));
    }
  };

  const validateForm = () => {
    const newErrors = {};
    Object.keys(formData).forEach(key => {
      const error = validateField(key, formData[key]);
      if (error) {
        newErrors[key] = error;
      }
    });
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateForm()) {
      setTouched({
        email: true,
        password: true,
      });
      return;
    }

    setIsLoading(true);
    setSubmitStatus({ type: '', message: '' });

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/signup`,
        formData
      );
      
      setSubmitStatus({
        type: 'success',
        message: 'Account created successfully! Please continue to login.',
      });
      setFormData({ email: '', password: '' });
      setTouched({ email: false, password: false });
    } catch (error) {
      await reportError(error, {
        component: 'SignupForm',
        action: 'handleSubmit',
        formData: {
          email: formData.email,
          hasPassword: !!formData.password,
        },
        validationState: {
          touched,
          errors,
        },
      });
      
      let errorMessage;
      if (error.response) {
        errorMessage = error.response.data.error || 
                      error.response.data.errors?.[0]?.msg ||
                      'An error occurred during signup. Please use HTTPS while we resolve this issue.';
      } else if (error.request) {
        errorMessage = 'Could not reach the server. Please use HTTPS while we resolve this issue.';
      } else {
        errorMessage = 'An unexpected error occurred. Please use HTTPS while we resolve this issue.';
      }

      setSubmitStatus({
        type: 'error',
        message: errorMessage
      });
    } finally {
      setIsLoading(false);
    }
  };

  React.useEffect(() => {
    const handleError = (event) => {
      reportError(event.error, {
        component: 'SignupForm',
        type: 'window.onerror',
      });
    };

    const handleUnhandledRejection = (event) => {
      reportError(event.reason, {
        component: 'SignupForm',
        type: 'unhandledRejection',
      });
    };

    window.addEventListener('error', handleError);
    window.addEventListener('unhandledrejection', handleUnhandledRejection);

    return () => {
      window.removeEventListener('error', handleError);
      window.removeEventListener('unhandledrejection', handleUnhandledRejection);
    };
  }, []);

  return (
    <Container maxWidth="sm">
      <Box
        sx={{
          marginTop: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Typography component="h1" variant="h4" sx={{ mb: 4 }}>
          Sign Up
        </Typography>

        {submitStatus.message && (
          <Alert
            severity={submitStatus.type}
            sx={{ width: '100%', mb: 2 }}
            role="alert"
          >
            {submitStatus.message}
          </Alert>
        )}

        <Box 
          component="form" 
          onSubmit={handleSubmit} 
          sx={{ width: '100%' }}
          noValidate
        >
          <TextField
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email Address"
            name="email"
            autoComplete="email"
            autoFocus
            value={formData.email}
            onChange={handleChange}
            onBlur={handleBlur}
            error={touched.email && !!errors.email}
            helperText={touched.email && errors.email}
            aria-describedby={errors.email ? 'email-error-text' : undefined}
            sx={{ mb: 2 }}
          />

          <TextField
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            autoComplete="new-password"
            value={formData.password}
            onChange={handleChange}
            onBlur={handleBlur}
            error={touched.password && !!errors.password}
            helperText={touched.password && errors.password}
            aria-describedby={errors.password ? 'password-error-text' : undefined}
            sx={{ mb: 3 }}
          />

          <Button
            type="submit"
            fullWidth
            variant="contained"
            disabled={isLoading}
            sx={{
              py: 1.5,
              bgcolor: 'black',
              '&:hover': {
                bgcolor: '#333',
              },
            }}
          >
            {isLoading ? <CircularProgress size={24} /> : 'Sign Up'}
          </Button>

          <Box sx={{ mt: 2, textAlign: 'center' }}>
            <Link
              href="#"
              variant="body2"
              sx={{ color: 'text.secondary' }}
              onClick={(e) => {
                e.preventDefault();
                navigate('/login');
              }}
            >
              Already have an account? Log In
            </Link>
          </Box>
        </Box>
      </Box>
    </Container>
  );
};

export default SignupForm;
