export const validateEmail = (email) => {
  if (!email) {
    return 'Email is required';
  }
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  if (!emailRegex.test(email)) {
    return 'Please enter a valid email address';
  }
  return '';
};

export const validatePassword = (password, isSignup = false) => {
  if (!password) {
    return 'Password is required';
  }
  if (isSignup && password.length < 8) {
    return 'Password must be at least 8 characters long';
  }
  if (isSignup && !/(?=.*[a-z])(?=.*[A-Z])/.test(password)) {
    return 'Password must contain both uppercase and lowercase letters';
  }
  return '';
};

export const validateConfirmPassword = (password, confirmPassword) => {
  if (!confirmPassword) {
    return 'Please confirm your password';
  }
  if (password !== confirmPassword) {
    return 'Passwords do not match';
  }
  return '';
};
