import React, { useState } from 'react';
import {
  TextField,
  Button,
  Typography,
  Box,
  Container,
  Alert,
  CircularProgress,
  Link,
} from '@mui/material';
import axios from 'axios';
import { useParams } from 'react-router-dom';

const PasswordResetForm = ({ onBackToLogin }) => {
  const { token } = useParams();
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [submitStatus, setSubmitStatus] = useState({ type: '', message: '' });
  const [errors, setErrors] = useState({});

  const validateForm = () => {
    const newErrors = {};
    if (password.length < 8) {
      newErrors.password = 'Password must be at least 8 characters';
    }
    if (password !== confirmPassword) {
      newErrors.confirmPassword = 'Passwords do not match';
    }
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateForm()) return;

    setIsLoading(true);
    setSubmitStatus({ type: '', message: '' });

    try {
      await axios.post(`${process.env.REACT_APP_API_URL}/api/password-reset`, {
        token,
        password,
      });
      setSubmitStatus({
        type: 'success',
        message: 'Password has been reset successfully. You can now log in with your new password.',
      });
      setPassword('');
      setConfirmPassword('');
    } catch (error) {
      setSubmitStatus({
        type: 'error',
        message: error.response?.data?.error || 'An error occurred. Please try again.',
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Container maxWidth="sm">
      <Box
        sx={{
          marginTop: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Typography component="h1" variant="h4" sx={{ mb: 4 }}>
          Set New Password
        </Typography>

        {submitStatus.message && (
          <Alert
            severity={submitStatus.type}
            sx={{ width: '100%', mb: 2 }}
          >
            {submitStatus.message}
          </Alert>
        )}

        <Box component="form" onSubmit={handleSubmit} sx={{ width: '100%' }}>
          <TextField
            margin="normal"
            required
            fullWidth
            name="password"
            label="New Password"
            type="password"
            id="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            error={!!errors.password}
            helperText={errors.password}
            sx={{ mb: 2 }}
          />

          <TextField
            margin="normal"
            required
            fullWidth
            name="confirmPassword"
            label="Confirm Password"
            type="password"
            id="confirmPassword"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
            error={!!errors.confirmPassword}
            helperText={errors.confirmPassword}
            sx={{ mb: 3 }}
          />

          <Button
            type="submit"
            fullWidth
            variant="contained"
            disabled={isLoading}
            sx={{
              py: 1.5,
              bgcolor: 'black',
              '&:hover': {
                bgcolor: '#333',
              },
            }}
          >
            {isLoading ? <CircularProgress size={24} /> : 'Reset Password'}
          </Button>

          {submitStatus.type === 'success' && (
            <Box sx={{ mt: 2, textAlign: 'center' }}>
              <Link
                href="#"
                variant="body2"
                sx={{ color: 'text.secondary' }}
                onClick={(e) => {
                  e.preventDefault();
                  onBackToLogin();
                }}
              >
                Back to Login
              </Link>
            </Box>
          )}
        </Box>
      </Box>
    </Container>
  );
};

export default PasswordResetForm;
